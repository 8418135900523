/**
 * Created by chendong on 2018/1/13.
 */
window.__e="dGVsZDIwMThzaWdua2V5";
var Teld = {}
Teld.Performance = {
    BrowserCompatibility:window.performance?true:false,

    // DNS查询耗时 ：domainLookupEnd - domainLookupStart
    // TCP链接耗时 ：connectEnd - connectStart
    // request请求耗时 ：responseEnd - responseStart
    // 解析dom树耗时 ： domComplete - domInteractive
    // 白屏时间 ：responseStart - navigationStart
    // domready时间 ：domContentLoadedEventEnd - navigationStart
    // onload时间 ：loadEventEnd - navigationStart

    getPerformanceInfo:function () {
        if(!window.performance)return null;
        var timing = window.performance.timing

        return {
            whiteScreenTime:timing.domLoading - timing.navigationStart,
            userEnable:timing.domContentLoadedEventEnd - timing.navigationStart,
            analyzeDom:timing.domComplete - timing.domInteractive,
            memory : performance.memory
        }

    }
};
window.cajess = function (endData,a,b) {
    window._a="6fb498553e3c462988c3b9573692bd5f";
    window._b="98d71fe589499968";
    var key = a?CryptoJS.enc.Utf8.parse(a):CryptoJS.enc.Utf8.parse(window.__a);
    var iv = b?CryptoJS.enc.Utf8.parse(b):CryptoJS.enc.Utf8.parse(window.__b);
    var encryptResult = CryptoJS.AES.encrypt(endData,key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Base64.stringify(encryptResult.ciphertext);
}

window.dcajess = function (endData,a,b) {
    window._a="6fb498553e3c462988c3b9573692bd5f";
    window._b="98d71fe589499968";
    var key = a?CryptoJS.enc.Utf8.parse(a):CryptoJS.enc.Utf8.parse(window.__a);
    var iv = b?CryptoJS.enc.Utf8.parse(b):CryptoJS.enc.Utf8.parse(window.__b);
    var baseResult=CryptoJS.enc.Base64.parse(endData);
    var ciphertext=CryptoJS.enc.Base64.stringify(baseResult);
    var decryptResult = CryptoJS.AES.decrypt(ciphertext,key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    var resData=CryptoJS.enc.Utf8.stringify(decryptResult);
    return JSON.parse(resData)
}
window._cajess = function (endData,a,b) {
    window._e="6fb498553e3c462988c3b9573692bd5f";
    window._f="98d71fe589499968";
    var key = a?CryptoJS.enc.Utf8.parse(a):CryptoJS.enc.Utf8.parse(window.__e);
    var iv = b?CryptoJS.enc.Utf8.parse(b):CryptoJS.enc.Utf8.parse(window.__f);
    var encryptResult = CryptoJS.AES.encrypt(endData,key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Base64.stringify(encryptResult.ciphertext);
}

window._dcajess = function (endData,a,b) {
    window._e="6fb498553e3c462988c3b9573692bd5f";
    window._f="98d71fe589499968";
    var key = a?CryptoJS.enc.Utf8.parse(a):CryptoJS.enc.Utf8.parse(window.__e);
    var iv = b?CryptoJS.enc.Utf8.parse(b):CryptoJS.enc.Utf8.parse(window.__f);
    var baseResult=CryptoJS.enc.Base64.parse(endData);
    var ciphertext=CryptoJS.enc.Base64.stringify(baseResult);
    var decryptResult = CryptoJS.AES.decrypt(ciphertext,key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    var resData=CryptoJS.enc.Utf8.stringify(decryptResult);
    return JSON.parse(resData)
}
//for bussinis
window.b_cajess = function (param) {

    var uts = Math.round(new Date().getTime()/1000)+""
    var uver = _cajess(uts).substring(0,16)
    var paramAfterEdit = _cajess(param,uts+"000000",uver)
    return JSON.stringify({
        Data:paramAfterEdit,
        UTS:uts,
        UVER:uver,
        UUID:(new Date()).getTime()+""+randomNum(10)
    })
}


window.b_dcajess = function (result) {
    var decryptData = _dcajess(result.data)
    var realData = _dcajess(decryptData.Data,decryptData.UTS+"000000",decryptData.UVER)
    return realData
}

window.AesEncode = function (param) {
    var ret = {}
    var paramkey = param.paramName
    var paramData = param.paremData
    if(Array.isArray(paramkey)){
        for(var i=0;i<paramkey.length;i++){
            ret[paramkey[i]] = b_cajess(paramData[i])
        }
    }else{
        ret[paramkey] = b_cajess(paramData)
    }
    return ret;
}

window.AesDecode = function (result) {
    if(!result || result.hasOwnProperty("data")==false || !result.data)return null;
    return b_dcajess(result)
}

var EncryptInterface = {
    "AES":{
        "encode":AesEncode,
        "decode":AesDecode
    }
}

window.dESEncrypt = function (val){
    var _a="UQInaE9V";
    var _b="siudqUQoprVNjiA7";
    var key = CryptoJS.enc.Utf8.parse(_a);
    var iv = CryptoJS.enc.Utf8.parse(_b);
    var encryptResult = CryptoJS.DES.encrypt(val,key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Base64.stringify(encryptResult.ciphertext);
}